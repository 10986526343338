import React from "react"

import { Modal } from "../components/Modal"
import App from "./index"

function Confirmation() {
  const redirect = function () {
    window.location.replace("/")
  }
  const details = {
    title: "Thanks for registering!",
    dismiss: "Cancel",
    save: "OK",
  }

  return (
    <div>
      <Modal button={false} details={details} onSave={redirect} open={true}>
        <p>
          Check your email for a verification email, and then you're all signed
          up.
        </p>
      </Modal>
      <App></App>
    </div>
  )
}
export default Confirmation
